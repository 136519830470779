import React from 'react'
import { Link } from 'gatsby'
import {Container, Button, Box, Typography, makeStyles, withStyles, List, ListItem, ListItemText} from '@material-ui/core'
import bannerImage from '../assets/images/AmanPersonLogo.png'

const useStyles = makeStyles({
  headerText: {
    color: 'white',
    fontSize: '30px',
    fontWeight: '600'
  },
  extraBold: {
    fontWeight: 600
  },
  redText: {
    color: '#e22727'
  },
  checkIcon: {
    color: 'green',
    fontSize: '24px'
  },
  link: {
    textDecoration: 'none'
  }
});

const PurchaseButton = withStyles({
  root: {
    backgroundColor: '#e22727',
    color: '#fff',
    lineHeight: '40px',
    fontSize: '30px',
    borderRadius: '4px',
    padding: '16px 30px',
    '&:hover, &:focus': {
      backgroundColor: '#b31414',
      color: '#fff',
      boxShadow: 'inset 0px 0px 0px 2px #fff',
    }
  },
  
})(Button)

export default function CheckoutBeats() {
  const classes = useStyles();

  return (
    <Container>
      <Box display="flex" alignItems="center" justifyContent="center" pt={5}>
        <img src={bannerImage} alt="banner"/>
      </Box>
      <Typography component="div">
        <Box m={2} mt={0} className={`${classes.extraBold} ${classes.redText}`} fontSize={40} textAlign="center">
          Choose your 4 beats below! 
        </Box>
        <Box m={2} mt={0} fontSize={24} textAlign="center">
          Looking for stems? Upgrade to <strong>4 trackouts for $50</strong> by selecting the option during checkout!
        </Box>

        <Box m={4} mt={6} fontSize={22} fontWeight={500} textAlign="center">
          Instructions:
        </Box>
        <List component='ol'>
          <ListItem>
            <ListItemText>
              1. Click the ADD button next to your favorite beats and select the license type (WAV or Trackout)
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              2. Add 4 beats total <strong>for the discount to take effect</strong>. Want more? Add <strong>up to 8 beats</strong> for the same discount.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              3. Click the cart icon on the top right to proceed to checkout
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              4. Once checkout is completed, the beats will be emailed instantly to you
            </ListItemText>
          </ListItem>
        </List>

        <Box m={1} mt={4} mb={2} display="flex" alignItems="center" justifyContent="center">
          <Link to='https://player.beatstars.com/?storeId=30975' className={classes.link}><PurchaseButton>Choose your beats here!</PurchaseButton></Link>
        </Box>
        <Box m={4} fontSize={24} textAlign="center"> 
          Or, choose your beats below:
        </Box>

        <Box mb={6} display="flex" alignItems="center" justifyContent="center">
          <iframe title="beatstore" src="https://player.beatstars.com/?storeId=30975" width="100%" height="800" style={{maxWidth: '1024px', border: 'none'}}> -- none -- </iframe>
        </Box>

      </Typography>
    
    </Container>
  )
}